import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom"; // Assuming you are using react-router-dom
import { makeRequest } from "../../config/api"; // Assuming your Axios file is in utils/axios
import {
  sectionStyle,
  containerStyle,
  formContainerStyle,
  titleStyle,
  inputContainerStyle,
  labelStyle,
  inputStyle,
  buttonContainerStyle,
  buttonStyle,
  footerTextStyle,
  errorStyle,
} from "../../style/styles";
import useAuth from "../../hooks/AuthHook";
import { enqueueSnackbar } from "notistack";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const emailParam = searchParams.get("email");
  const { login } = useAuth();
  const navigate = useNavigate();
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      const resetToken = searchParams.get("resetToken");
      if (!resetToken) {
        throw new Error("Invalid reset token");
      }
      if (!emailParam) {
        throw new Error("Invalid email");
      }
      if (!password) {
        throw new Error("Password is required");
      }
      if (password !== confirmPassword) {
        throw new Error("Passwords do not match");
      }
      const payLoad = {
        email: emailParam,
        newPassword: password,
        resetToken: searchParams.get("resetToken"),
      };
      const response = await makeRequest({
        method: "post",
        endpoint: "/api/auth/reset-password",
        data: payLoad,
      });
      if (response.token) {
        setSuccess(true);
        setLoading(false);
        enqueueSnackbar(response?.message || "Password updated successfully", {
          variant: "success",
        });
        login(response.token);
        navigate("/");
      } else {
        throw new Error(response?.message || "An error occurred");
      }
    } catch (error) {
      setError(error.message || "An error occurred");
      setLoading(false);
      enqueueSnackbar(
        error.message || "Something wen't wrong. Please try again.",
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <section className={sectionStyle}>
      <div className={containerStyle}>
        <div className={formContainerStyle}>
          <h2 className={titleStyle}>{"Reset Password"}</h2>
          <form onSubmit={handleSubmit}>
            <div className={inputContainerStyle}>
              <label htmlFor="email" className={labelStyle}>
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className={inputStyle}
                placeholder="Enter your email"
                value={emailParam}
                onChange={(e) => setEmail(e.target.value)}
                disabled={true}
              />
            </div>
            <div className={inputContainerStyle}>
              <label htmlFor="password" className={labelStyle}>
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                className={inputStyle}
                placeholder="Enter new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={loading}
              />
            </div>
            <div className={inputContainerStyle}>
              <label htmlFor="confirm-password" className={labelStyle}>
                Confirm Password
              </label>
              <input
                type="password"
                id="confirm-password"
                name="confirm-password"
                className={inputStyle}
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                disabled={loading}
              />
            </div>
            {/* {error && <p className={errorStyle}>{error}</p>} */}
            <div className={buttonContainerStyle}>
              <button className={buttonStyle} type="submit" disabled={loading}>
                {success ? "Updated" : loading ? "Loading..." : "Update"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
