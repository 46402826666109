// src/components/Spinner.js
import React from 'react';
import { css, keyframes } from '@emotion/css';

// Define keyframes for the spinner animation
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Define the spinner style using @emotion/css
const spinnerStyle = css`
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

const Spinner = () => {
  return <div className={spinnerStyle}></div>;
};

export default Spinner;
