import React, { useContext, useEffect, useState } from "react";
import useAuth from "../hooks/AuthHook";
import axios from "axios";
import { BASE_URL } from "../config/constants";
import { AuthContext } from "../context/AuthContext";
import Typography from "@mui/material/Typography";

const WelcomeCard = () => {
  const [name, setName] = useState("");
  const { isLoggedIn } = useAuth();
  const { token } = useContext(AuthContext);
  useEffect(() => {
    const fetchData = async () => {
      if (isLoggedIn) {
        const response = await axios.get(`${BASE_URL}/api/auth/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setName(response?.data?.user?.name);
      } else {
        setName("");
      }
    };
    fetchData();
  }, [isLoggedIn, token]);
  return isLoggedIn && name ? (
    <Typography
      variant="body1"
      sx={{
        width: "100%",
        textAlign: "center",
        py: 1,
      }}
      className="capitalize"
    >
      Hi,{" "}
      <span
        style={{
          fontWeight: "medium",
        }}
      >
        {name}
      </span>
    </Typography>
  ) : null;
};

export default WelcomeCard;
