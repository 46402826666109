import { Suspense } from "react";
import SpinnerWrapper from "../components/SpinnerWrapper";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<SpinnerWrapper />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
