import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Assuming you are using react-router-dom
import { makeRequest } from "./../../config/api"; // Assuming your Axios file is in utils/axios
import {
  sectionStyle,
  containerStyle,
  formContainerStyle,
  titleStyle,
  inputContainerStyle,
  labelStyle,
  inputStyle,
  buttonContainerStyle,
  buttonStyle,
  footerTextStyle,
  errorStyle,
} from "./../../style/styles";
import useAuth from "../../hooks/AuthHook";
import { enqueueSnackbar } from "notistack";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const response = await makeRequest({
        method: "post",
        endpoint: "/api/auth/forgot-password",
        data: { email },
      });
      
      if (response) {
        setSuccess(true);
        enqueueSnackbar(response?.message || "Email sent successfully", {
          variant: "success",
        });
        setLoading(false);
      }
      else { 
        throw new Error(response?.data?.message || "An error occurred");
      }
    } catch (error) {
      setError(error.message || "An error occurred");
      enqueueSnackbar(
        error.message || "Something wen't wrong. Please try again.",
        {
          variant: "error",
        }
      );
      setLoading(false);
    }
  };

  return (
    <section className={sectionStyle}>
      <div className={containerStyle}>
        <div className={formContainerStyle}>
          <h2 className={titleStyle}>{"Forgot Password"}</h2>
          <form onSubmit={handleSubmit}>
            <div className={inputContainerStyle}>
              <label htmlFor="email" className={labelStyle}>
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className={inputStyle}
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading || success}
              />
            </div>
            {/* {error && <p className={errorStyle}>{error}</p>} */}
            <div className={buttonContainerStyle}>
              <button className={buttonStyle} type="submit" disabled={loading || success}>
                {success ? "Email Sent" : loading ? "Loading..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
