import { lazy } from "react";
import Loadable from "./loadable";

const Read = Loadable(lazy(() => import("../pages/read")));

const NoLayoutRoutes = {
  path: "/",
  children: [
    {
        path: '/read/:id',
        element: <Read />,
    }
  ],
};

export default NoLayoutRoutes;
