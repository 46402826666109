import GeneralRoutes from './GeneralRoutes';
import { useRoutes } from 'react-router-dom';
import NoLayoutRoutes from './NoLayoutRoutes';
import ProtectedRoutes from './ProtectedRoutes';

export default function RootRouter() {
  return useRoutes([
    // ProtectedRoutes,
    GeneralRoutes,
    NoLayoutRoutes
  ]);
};