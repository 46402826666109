import { createContext } from "react";
import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext({
  isLoggedIn: false,
  token: null,
  isLoading: true,
  login: (token: string) => {},
  logout: () => {},
});


export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const navigate = useNavigate();

  const login = useCallback((token) => {
    setToken(token);
    localStorage.setItem(
      "authData",
      JSON.stringify({
        token: token,
      })
    );
    setIsLoggedIn(true);
    // navigate("/");
  }, []);


  const logout = useCallback(() => {
    setLoading(true);
    setToken(null);
    localStorage.removeItem("authData");
    setLoading(false);
    navigate("/login");

    setIsLoggedIn(false);
  }, []);

    useEffect(() => {
      setLoading(true);
      const storedData = JSON.parse(localStorage.getItem("authData"));

      if (storedData && storedData.token) {
        login(storedData.token);
      }
      setLoading(false);
    }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        login,
        logout,
        isLoading: loading,
        isLoggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
