import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL } from "./constants";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

interface RequestConfig extends AxiosRequestConfig {
  method: "get" | "post" | "put" | "delete" | "patch";
  endpoint: string;
  data?: any;
}

export const makeRequest = async (
  { method, endpoint, data }: RequestConfig,
  includeToken = true
) => {
  try {
    const config: AxiosRequestConfig = {
      method,
      url: endpoint,
      data,
    };

    if (includeToken) {
      const raw = localStorage.getItem("authData"); // Fetch token from localStorage
      const token = raw ? JSON.parse(raw)?.token : null;
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
      }
    }

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message || error.message);
    } else {
      throw new Error("Unexpected error occurred");
    }
  }
};

export default axiosInstance;
