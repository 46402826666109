import { css } from '@emotion/css';

export const sectionStyle = css`
  font-family: 'Arial', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f4f8;
  padding: 0 16px; /* Add padding to prevent overflow */
`;

export const sectionStyleModal = css`
  font-family: 'Arial', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 0 16px; /* Add padding to prevent overflow */
`;


export const containerStyle = css`
  max-width: 400px;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  @media (max-width: 480px) {
    padding: 16px;
  }
`;

export const formContainerStyle = css`
  background-color: #fff;
  border-radius: 8px;
  padding: 32px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 480px) {
    padding: 24px;
  }
`;

export const modalFormContainerStyle = css`
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 480px) {
    padding: 24px;
  }
`;

export const titleStyle = css`
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 24px;
  text-align: center;
  color: #1a202c;
  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

export const inputContainerStyle = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;
  box-sizing: border-box;
`;

export const labelStyle = css`
  display: block;
  font-size: 0.875rem;
  color: #4a5568;
  margin-bottom: 8px;
  width: 100%;
  box-sizing: border-box;
`;

export const inputStyle = css`
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d2d6dc;
  padding: 12px;
  font-size: 1rem;
  color: #4a5568;
  outline: none;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  box-sizing: border-box;
  &:focus {
    border-color: #667eea;
    box-shadow: 0 0 0 2px rgba(102, 126, 234, 0.5);
  }
`;

export const buttonContainerStyle = css`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  width: 100%;
  box-sizing: border-box;
`;

export const buttonStyle = css`
  width: 50%; /* Adjust the width as needed */
  padding: 12px;
  border: none;
  border-radius: 4px;
  background-color: #38a169;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: #2f855a;
  }
  &:focus {
    outline: none;
  }
  @media (max-width: 480px) {
    width: 100%; /* Make the button full-width on smaller screens */
  }
`;

export const footerTextStyle = css`
  font-size: 0.875rem;
  color: #718096;
  text-align: center;
  margin-top: 16px;
  a {
    color: #38a169;
    text-decoration: none;
  }
`;

export const errorStyle = css`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`;