import { Box, Typography, Rating, Button, Avatar } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useContext, useEffect, useState } from "react";
import { format } from "date-fns";
import axios from "axios";
import { BASE_URL } from "../config/constants";
import { AuthContext } from "../context/AuthContext";
import { useSnackbar } from "notistack";
import PersonIcon from "@mui/icons-material/Person";
import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const formatDate = (dateString) => {
  try {
    const date = new Date(dateString); // Convert to Date object
    return format(date, "MM/dd/yyyy"); // Format as "11/22/2024" using date-fns
  } catch (error) {
    console.error("Invalid date:", error);
    return "Invalid Date";
  }
};
const VisitorsCard = ({ tag }: { tag: any }) => {
  const [expandedComments, setExpandedComments] = useState(false);
  const [initialValue, setInitialValue] = useState({ ...tag });
  const [value, setValue] = useState({
    startDate: null,
    endDate: dayjs(),
  });
  const { token } = useContext(AuthContext);
  useEffect(() => {
    setInitialValue({ ...tag });
    setValue({
      startDate: dayjs(tag?.createdAt),
      endDate: dayjs(),
    });
  }, [tag]);
  //   useEffect(() => {}, [initialValue]);

  const { enqueueSnackbar } = useSnackbar();
  const handleDateChange = async (newValue, tagId) => {
    setValue(newValue);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/dummy/my-visitors/${tag._id}`,
        {
          params: { startDate: newValue.startDate, endDate: newValue.endDate },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Set visitors directly from response.data.result
        // console.log("previous visitors", visitors);
        setInitialValue(response.data.result);
        // console.log("updated visitors", response.data.result);
      } else {
        enqueueSnackbar("Error fetching data", { variant: "error" });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      enqueueSnackbar("Error fetching data", { variant: "error" });
    }
  };

  return (
    <Box
      key={initialValue?._id}
      sx={{
        mb: 2,
        p: 2,
        border: "1px solid black",
        // boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        // overflow: "hidden",
      }}
    >
      <Box sx={{ mb: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {initialValue?.title}
          </Typography>
        </Box>
        {initialValue?.location && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              color: "text.secondary",
              mb: 1,
            }}
          >
            {/* <LocationOnIcon fontSize="small" /> */}
            <Typography variant="body2">{initialValue?.location}</Typography>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2.5,
         
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Rating
            value={initialValue?.averageRating || 0}
            readOnly
            precision={0.5}
            size="small"
          />
          <Typography variant="body2" color="text.secondary">
            ({initialValue?.totalRating || 0})
          </Typography>
        </Box>
        <Typography variant="body2">
          Visitors: {initialValue?.totalVisitors || 0}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: {
            xs: "column", // Stack vertically on small screens
            sm: "row", // Side by side on larger screens
          },
          justifyContent: "right",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column", // Stack vertically on small screens
              sm: "row", // Side by side on larger screens
            },
            gap: 1,
            width: {
              xs: "100%", // Full width on small screens
              sm: "auto", // Auto width on larger screens
            },
            "& .MuiTextField-root": {
              width: {
                xs: "100%", // Full width on small screens
                sm: "132px", // Fixed width on larger screens
              },
            },
            "& .MuiInputLabel-root": {
              fontSize: "0.8rem",
            },
            "& .MuiInputBase-input": {
              padding: "8px",
              fontSize: "0.8rem",
            },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              closeOnSelect
              label="Start Date"
              value={value.startDate}
              onChange={(newValue) =>
                handleDateChange(
                  {
                    ...value,
                    startDate: newValue,
                  },
                  tag?._id
                )
              }
              slotProps={{
                textField: {
                  size: "small",
                  sx: {
                    backgroundColor: "#fff",
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#f1356d",
                      },
                    },
                  },
                },
              }}
            />
            <DatePicker
              closeOnSelect
              label="End Date"
              value={value.endDate}
              onChange={(newValue) =>
                handleDateChange(
                  {
                    ...value,
                    endDate: newValue,
                  },
                  tag?._id
                )
              }
              slotProps={{
                textField: {
                  size: "small",
                  sx: {
                    backgroundColor: "#fff",
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#f1356d",
                      },
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      {
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1,
            }}
          >
            {/* Comments Section */}
            <Box sx={{ flex: 1 }}>
              <Button
                onClick={() =>
                  setExpandedComments(
                    // expandedComments === initialValue?._id ? null : initialValue?._id
                    true
                  )
                }
                endIcon={
                  expandedComments === initialValue?._id ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )
                }
                sx={{
                  textTransform: "none",
                  color: "text.secondary",
                  p: 0,
                  "&:hover": { background: "none" },
                }}
              >
                Comments
              </Button>
              {expandedComments && (
                <Box
                  sx={{
                    mt: 1,
                    pl: 2,
                    border: "0.4px solid grey",
                    borderRadius: "10px",
                    paddingTop: "20px",
                  }}
                >
                  {initialValue?.comments.map((comment, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 1,
                        mb: 2,
                        padding: "4px 0",
                      }}
                    >
                      <Avatar
                        sx={{
                          width: 32,
                          height: 32,
                          bgcolor: "#e4e6eb", // Light gray background
                        }}
                      >
                        <PersonIcon sx={{ color: "#65676b" }} />
                      </Avatar>
                      <Box>
                        <Box
                          sx={{
                            backgroundColor: "#e4e6eb", // Light gray background
                            borderRadius: "18px",
                            padding: "8px 12px",
                            maxWidth: "300px",
                            wordWrap: "break-word",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#050505", // Dark text color
                              fontSize: "0.9rem",
                            }}
                          >
                            {comment.comment}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </>
      }
    </Box>
  );
};

export default VisitorsCard;
