import { lazy } from "react";
import Loadable from "./loadable";
import Navbar from "../layout/Navbar";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";

const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const SignUp = Loadable(lazy(() => import("../pages/auth/Signup")));
const Logout = Loadable(lazy(() => import("../pages/auth/Logout")));
const Create = Loadable(lazy(() => import("../pages/create")));
const Tags = Loadable(lazy(() => import("../pages/tags")));

const GeneralRoutes = {
  path: "/",
  element: <Navbar />,
  children: [
    {
      path: "/",
      element: <Create />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/signup",
      element: <SignUp />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/my-tag",
      element: <Tags />,
    },

    //   path: "/*",
    //   element: <PageNotFound />,
    // },
  ],
};

export default GeneralRoutes;
